@import "styles/core.scss";
.dividerContainer {
  position: relative;
  height: 0;
  width: 100%;
}

.divider {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 100%;
  border-top: var(--borders-weight-sm) solid var(--color-border-separator);
}
