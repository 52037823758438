@import "styles/core.scss";
// Carousel overflows it's own container (see cards in the margins)
// This is needed so it doesn't overflow the whole page
.hideXOverflow {
  overflow-x: hidden;
}

.container {
  position: relative;
  width: 100%;
  max-width: calc(var(--breakpoint-lg-size) - (2 * var(--breakpoint-lg-margin)));
  margin: 0 auto;
  transition: margin 0.3s ease;

  @include page-width-lg-down {
    width: unset;
    margin: 0 var(--breakpoint-lg-margin);
  }

  @include page-width-md-down {
    margin: 0 var(--breakpoint-md-margin);
  }

  @include page-width-sm-down {
    margin: 0 var(--space-300);
  }

  @include page-width-xs {
    margin: 0 var(--space-200);
  }
}

.dividerPadding {
  padding-bottom: var(--space-1200);

  @include page-width-sm-down {
    padding-bottom: var(--space-600);
  }
}
